<template>
	<div>
		<Hero image="../img/13.jpg" />
		<main>
			<div class="container">
				<Heading title="Our Core Values" desc="Enlighten, Empower, Excel" />
				<div class="content">
					<div class="row">
						<div class="col-md-8">
							<Values />
						</div>
						<div class="col-md-4">
							<MenuAbout />
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	components: {
		Values: () => import("@/components/AboutUs/Values.vue")
	},
};
</script>
